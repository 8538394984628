<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-card-subtitle v-if="mode!='read'">{{$t('m.qrCode.subtitle')}}</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <validation-provider
              ref="userIdProvider"
              :name="$t('m.qrCode.label')"
              :rules="{ required:true }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="qrCodeLabel_"
                :label="$t('m.qrCode.label')"
                :error-messages="errors"
                :readonly="mode=='read'"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <div v-if="qrCodeURL==''">
          <v-row justify="center">
            <v-col cols="auto" align-self="center">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-col>
            <v-col cols="auto" align-self="center">{{$t('m.qrCode.creating')}}</v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row justify="center">
            <VueQr :dotScale=".5" :text="qrCodeURL" />
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <a :href="qrCodeURL">{{qrCodeURL}}</a>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div v-if="mode=='read'">
          <v-btn text @click="ok">{{$t('m.ok')}}</v-btn>
        </div>
        <div v-else>
          <v-btn text @click="cancel" v-if="!hideCancel">{{$t('m.cancel')}}</v-btn>
          <v-btn text :disabled="invalid" @click="save">{{$t('m.save')}}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueQr from 'vue-qr'

export default {
  name: 'qr-code',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueQr
  },
  props: {
    title: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: 'create'
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    qrCodeLabel: {
      type: String,
      required: false,
      default: ''
    },
    qrCodeURL: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    qrCodeLabel_: {
      get () { return this.qrCodeLabel },
      set (value) { this.$emit('qrCodeLabelChanged', value) }
    }
  },
  methods: {
    ok () {
      this.$emit('ok')
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      this.$emit('save')
    }
  }
}
</script>
