<template>
  <v-row  >
    <v-spacer/>
    <v-col cols="auto" v-if="loading">
      <v-progress-circular indeterminate color="primary"/>
    </v-col>
    <v-col cols="10" sm="6" md="4" v-else>
      <qr-code
        :title="$t('m.media.createDialog.title')"
        :qrCodeLabel="qrCodeLabel"
        :qrCodeURL="qrCodeURL"
        :hideCancel="true"
        @qrCodeLabelChanged="onQRCodeLabelChanged"
        @cancel="onCreateCancel"
        @save="onCreateSave"
      />
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { shrinkUrl } from '@/helpers/referral'
import { addQRCode } from '@/helpers/firebase-functions'

import qrCode from '@/components/qrcode/QRCode'

export default {
  components: {
    qrCode
  },
  data: function () {
    return {
      loading: false,
      ownerScenarioId: '',
      qrCodeLabel: '',
      qrCodeURL: '',
      qrCodeId: ''
    }
  },
  methods: {
    randomId (length) {
      let id = ''
      const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const charsLength = chars.length
      for (let i = 0; i < length; i++) {
        id += chars[Math.floor(Math.random() * charsLength)]
      }
      return id
    },
    async generate () {
      const ownerId = this.ownerId
      const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
      this.ownerScenarioId = ownerScenarioId
      this.qrCodeId = this.randomId(20)

      const absoluteUrl = window ? window.location.origin : ''
      const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: this.qrCodeId, campaignType: 'jobsite' })
      this.qrCodeURL = await shrinkUrl(`${absoluteUrl}/jobs/${ownerId}/${ownerScenarioId}?campaignInfo=${campaignInfo}`)
    },
    onQRCodeLabelChanged (value) {
      this.qrCodeLabel = value
    },
    onCreateCancel () {},
    async onCreateSave () {
      this.loading = !this.loading

      const qrCode = {
        label: this.qrCodeLabel,
        url: this.qrCodeURL,
        enabled: true,
        count: 0,
        qrCodeId: this.qrCodeId,
        qrCodeType: 'jobsite'
      }
      await addQRCode({ ownerId: this.ownerId, scenarioId: this.ownerScenarioId, qrCode })
      this.$router.replace(`/qr-codes/${this.ownerId}`)

      this.loading = !this.loading
    }
  },
  async mounted () {
    this.ownerId = this.$route.params.ownerId
    await this.generate()
  }
}
</script>
